export default [
  // {
  //   header: 'Configuração',
  // },
  {
    title: 'Configuração',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Temas',
        route: 'temas-lista',
        // ACL
        resource: 'TemasLista',
        action: 'read',
      },
      {
        title: 'Estilos',
        route: 'estilos-lista',
        // ACL
        resource: 'EstilosLista',
        action: 'read',
      },
      {
        title: 'Parâmetros',
        icon: 'CompassIcon',
        route: 'parametro-lista',
        // ACL
        resource: 'ParametroLista',
        action: 'read',
      },
      {
        title: 'Segurança',
        icon: 'ShieldIcon',
        // ACL
        action: 'read',
        children: [
          {
            title: 'Perfil',
            icon: 'SettingsIcon',
            route: 'perfil-lista',
            // ACL
            resource: 'PerfilLista',
            action: 'read',
          },
          {
            title: 'Funcionalidade',
            route: 'ability-lista',
            // ACL
            resource: 'FuncionalidadeLista',
            action: 'read',
          },    
        ],
      },
    ],
  },
]
